import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import ContentPage from "../layouts/contentPage"
import CategoryBox from "../components/categoryBox"
import ProducstList from "../components/productsList"
import Seo from "../components/seo"



const Products = () => (
    <Layout>
      <Seo title="Produkty" />
      <ContentPage>
          <ProducstList>
            <CategoryBox className="energyze" name="Energyze" color="lime" bgtext="WIT.C" url="/produkty/energyze"/>
            <CategoryBox className="relift" name="Relift" color="magenta" bgtext="WIT.A" url="/produkty/relift"/>
            <CategoryBox className="acid" name="Acid Peel" color="blue" bgtext="AHA" bgtext2="PHA" url="/produkty/acid-peel"/>
          </ProducstList>
      </ContentPage>
    </Layout>
)

export default Products
